.Modal__bg__2TDwK {
  position: absolute;
  background: rgba(22, 101, 102, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999999999999999999;
}

.DownLoadWeb__content__ATHya {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.DownLoadWeb__content__ATHya .DownLoadWeb__steps__1184U {
  position: absolute;
  top: 100px;
}
.DownLoadWeb__content__ATHya .DownLoadWeb__content_box__1iOUw {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.DownLoadWeb__content__ATHya .DownLoadWeb__loading__1CHtB {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

