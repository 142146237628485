.bg {
  position: absolute;
  background: rgba(22, 101, 102, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999999999999999999;
}
